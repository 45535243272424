@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rosarivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

html,
body {
  background-color: #FFEDE0;
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
iframe {
	margin: 20px 0;
	border: none;
		width: calc(100vw - 20%);
		height: calc(80vw * 0.57) !important;
	}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.wmde-markdown>blockquote {
  text-align: center;
  position: relative;
  font-family: Rosarivo;
  font-weight: 400;
  font-size: 32px;
  line-height: 45px;
  border-left: none !important;
  width: 700px;
  margin: 70px auto 50px !important
}

blockquote>p {
  color: black;
  opacity: 1;
  padding: 30px 0 40px;
}

blockquote::before {
  position: absolute;
  content: "";
  width: 250px;
  height: 100%;
  top: -10px;
  left: calc(350px - 125px);
  border-top: solid 3px black;
  border-bottom: solid 3px black;
}

footer svg {
  height: 27px;
}
